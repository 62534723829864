import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostMiniCard from "../components/post-mini-card"

const PostTemplate = ({ data, location }) => {
  const presenter = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const postsByPresenter = data.lessons.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={presenter.frontmatter.name}
        description={presenter.frontmatter.description || presenter.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{presenter.frontmatter.name}</h1>
        </header>
        <section>
          <div className="presenter-description" dangerouslySetInnerHTML={{ __html: presenter.html }}
          itemProp="articleBody"
          />
        </section>

        <section>
          <h2 className="mt-4">
            Lessons:
            <span className="align-middle inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{postsByPresenter.length}</span>
          </h2>
          {
            (postsByPresenter.map(post => {
              return (
                <PostMiniCard post={post} />
              )
            }))
          }
          
        </section>
      </article>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query PresenterBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $presenterId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
      }
    }
    lessons: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          presenters: {
            elemMatch: {
              frontmatter: {
                presenterId: {eq: $presenterId}
              }
            }
          },
          docType: {eq: "post"}
        }
      }
    ) {
      totalCount
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          lessonDate(formatString: "MMMM DD, YYYY")
          title
          postId
          postType
          passages
          series {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          presenters {
            frontmatter {
              name
              presenterId
            }
          }
          topics {
            frontmatter {
              topicId
            }
          }
          venue {
            frontmatter {
              name
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
